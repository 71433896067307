import React, { useEffect, useState } from 'react';
import '../components/css/game.css';
import gameImage from './media/image.png';
import axios, { AxiosResponse } from 'axios';
import {BASE_URL} from './Data';


interface InviteProps {

};




interface allGames {
    Game: Array<{
        GameId: number,
        GameCode: number,
        GameName: string
        GameType: number,
        ImageUrl: string,
        Method: string,
        IsH5Support: boolean,
        Maintenance: string,
        GameLobbyConfig: any,
        OtherName: [],
        HasDemo: boolean,
        Sequence: number,
        GameEvent: [],
        GameProvideCode: string,
        GameProvideName: string
    }>,
    Status: number,
    Description: number,
    ResponseDateTime: string

}

interface gameLoginRequestInterface{
    request:boolean,
    Game:{
        GameId: number,
        GameCode: number,
        GameName: string
        GameType: number,
        ImageUrl: string,
        Method: string,
        IsH5Support: boolean,
        Maintenance: string,
        GameLobbyConfig: any,
        OtherName: [],
        HasDemo: boolean,
        Sequence: number,
        GameEvent: [],
        GameProvideCode: string,
        GameProvideName: string
    }
}






const GamePage: React.FC<InviteProps> = () => {
    const [gameList, setGameList] = useState<allGames>();

    const [gameCode,SetGameCode] = useState<string>();

    const [gameLoginRequest,changeGameLoginRequest] = useState<gameLoginRequestInterface>();

    const [ip, setIp] = useState('');
    useEffect(() => {
        const fetchIp = async () => {
            try {
                const response = await fetch('https://api.ipify.org?format=json');
                const data = await response.json();
                setIp(data.ip);
                
            } catch (error) {
                console.error('Error fetching IP:', error);
            }
        };

        fetchIp();
    }, []);
    useEffect(() => {
        
        SetGameCode('MGH5');

        axios.get(BASE_URL+'/api/getGames')
            .then((response: AxiosResponse) => {
                setGameList(response.data);// Handle the response data

            })
            .catch((error: Error) => {
                console.error(error); // Handle errors
            });
    }, [])

    useEffect(()=>{
        if(gameLoginRequest?.request === true && ip!=null){
            
            axios.post(`${BASE_URL}/api/gameLogin`, {
                game: gameLoginRequest.Game,
                ip: ip,
                user:window.Telegram?.WebApp.initDataUnsafe.user
              },
             {
                headers: { 
                  'Content-Type': 'application/json',
                },
                withCredentials: true,
              }
            )
              .then(response => {
                var url = response.data.Url;
                if(response.data.Url != null){
                    window.location.replace(url);
                }
              })
              .catch(error => {
                console.error(error.response?.data || error.message);
              });

        } 
    },[gameLoginRequest]);

    useEffect(() => {
        console.log(gameList);
    }, [gameList])





    return (
        <>
            <div className="game-sec1">
                <button className={ gameCode == 'MGH5' ?'selected':''} onClick={()=>{SetGameCode('MGH5')}}>Mega888</button>
                {/* <button>PG Soft</button> */}
                <button className={ gameCode == 'PPLAY' ?'selected':''} onClick={()=>{SetGameCode('PPLAY')}}>Pragmatic</button>
            </div>

            <div className="game-sec2">
                { 
                
                
                 gameList?.Game?.map((element, index) => (
                    
                    element.GameProvideCode == gameCode ? (
                    <button style={{backgroundColor:'transparent',border:'none',cursor:'pointer'}}
                    key={index} onClick={()=>{changeGameLoginRequest({request:true,Game:element})}}>
                        <div className="game">
                            <img src={element.ImageUrl} alt={element.GameName} />
                            <p>{element.GameName}</p>
                        </div>
                    </button>):(null)
                    
                ))
                
                }

            </div>
        </>
    )
}



export default GamePage;