import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import './App.css';
import HomePage from './components/HomePage';
import MissionsPage from './components/Missions';
import InvitePage from './components/Invite';
import Layout from './Layout';
import GamePage from './components/Game';
import PaymentPage from './components/Payment';
import { TelegramWebApp } from './components/Data';
// import LayoutPage from './components/LayoutPage';
declare global {
    interface Window {
        Telegram?: {
            WebApp: TelegramWebApp;
        };
    }
}
function App() {
    return (
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route path="/" element={<HomePage />} />
                        <Route path="missions/" element={<MissionsPage />} />
                        <Route path="invite/" element={<InvitePage />} />
                        <Route path="game/" element={<GamePage />} />
                        <Route path="payment/" element={<PaymentPage />} />
                    </Route>
                </Routes>
            </Router>
            {/* <LayoutPage /> */}
        </>
    );
}

export default App;
